import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { Horizon, HorizonType } from '@shapeable/collaborate-ore-types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, useEntity, useLang } from '@shapeable/web';
import { HorizonOne } from '../icons/horizon-one';
import { useHorizonTypes } from '../../hooks/use-horizon-types';
const cls = classNames('horizon-table');

// -------- Types -------->

export type HorizonTableProps = Classable & HasChildren & { 
  items?: Horizon[];
};

export const HorizonTableDefaultProps: HorizonTableProps = {
  items:[]
};

// -------- Child Component Props -------->

type ContainerProps = {

}

type HorizonProp = {
  value: number;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  `,
});

const TitleStyles = breakpoints({
  base: css`
    color: #000;
    text-align: center;
    font-family: IBM Plex Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 106.6%;
    padding-top: 20px;
  `,
});

const LayoutStyles = breakpoints({
  base: css`
    border-collapse: collapse;
    border-spacing: 5px; 
  `,
});

const BodyStyles = breakpoints({
  base: css`
    
  `,
});



const HorizonNumberStyles = breakpoints({
  base: css`
    
  `,
});

const NumberCellStyles = breakpoints({
  base: css`
    width: 30px;
    height: 30px;
    text-align: center;
    border: 1px solid var(--shapeable-line, #E5E5E5);
  `,
});

const HorizonTickStyles = breakpoints({
  base: css`
    
  `,
});

const TickStyles = breakpoints({
  base: css`
    width: 20px;
    height: 60px;
    text-align: center;
    border: 1px solid var(--shapeable-line, #E5E5E5);
    border-bottom: none;
  `,
});

const HorizonStyles = breakpoints({
  base: css`
    ${({ value }: HorizonProp) => (value === 1 && css` 
      ${theme.FILL('#5A5A5A')}
    `)}
    ${({ value }: HorizonProp) => (value === 2 && css` 
      ${theme.FILL('#969696')}
    `)}
    ${({ value }: HorizonProp) => (value === 3 && css` 
      ${theme.FILL('#C4C4C4')}
    `)}
  `,
});





// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Title: styled.span`${TitleStyles}`,
    Layout: styled.table`${LayoutStyles}`,
      Body: styled.tbody`${BodyStyles}`,
      HorizonNumber: styled.tr`${HorizonNumberStyles}`,
        NumberCell: styled.th`${NumberCellStyles}`,
      HorizonTick: styled.tr`${HorizonTickStyles}`,
        Tick: styled.td`${TickStyles}`,
          Horizon: styled(HorizonOne)<HorizonProp>`${HorizonStyles}`,

};

export const HorizonTable: Shapeable.FC<HorizonTableProps> = (props) => {
  const { className, children, items } = props;

  const t = useLang();

  const horizonTypes = useHorizonTypes();
  const hasHorizonTypes = !!horizonTypes.length;

  const types = items.map((item) => item?.type)
  const horizonValue = types.map((type: HorizonType) => type?.value );
  
  return (
   <My.Container className={cls.name(className)}>
      <My.Title>{t('HORIZON')}</My.Title>
      <My.Layout>
      <My.Body>
        <My.HorizonNumber>
          {
            hasHorizonTypes &&
            horizonTypes.map((type, index) => (
              <My.NumberCell key={index}>{index + 1}</My.NumberCell>
            ))
          }
        </My.HorizonNumber>
        <My.HorizonTick>
          {
            hasHorizonTypes &&
            horizonTypes.map((type, index) => {
              const { value } = type;
              return (
                <My.Tick key={index}>
                  {horizonValue.includes(value) && <My.Horizon key={index + 1} value={value}/>}
                </My.Tick>
            )})
          }
        </My.HorizonTick>
        </My.Body>
        {children}
      </My.Layout>
   </My.Container>
  )
};

HorizonTable.defaultProps = HorizonTableDefaultProps;
HorizonTable.cls = cls;