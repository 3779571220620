import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity } from '@shapeable/types';
import { Topic } from '@shapeable/collaborate-ore-types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, useEntity } from '@shapeable/web';
import { ValueChainMap } from '../elements/value-chain-map';
const cls = classNames('topic-Aside-layout');

// -------- Types -------->

export type TopicAsideLayoutProps = Classable & HasChildren & { 
  entity?: Topic;
};

export const TopicAsideLayoutDefaultProps: Omit<TopicAsideLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    padding-right: ${theme.UNIT(3)};
  `,
});

const MapStyles = breakpoints({
  base: css`
    max-width: 100%;
    margin-top: ${theme.UNIT(2)};
    height: auto;
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Map: styled(ValueChainMap)`${MapStyles}`,
};

export const TopicAsideLayout: Shapeable.FC<TopicAsideLayoutProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);
  
  return (
    <My.Container className={cls.name(className)}>
      <My.Map />
    </My.Container>
  )
};

TopicAsideLayout.defaultProps = TopicAsideLayoutDefaultProps;
TopicAsideLayout.cls = cls;