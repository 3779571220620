import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, ContentNode as TContentNode } from '@shapeable/types';
import { Topic } from '@shapeable/collaborate-ore-types';
import { breakpoints, theme } from '@shapeable/theme';
import { ContentEntityIntro, ContentNode, EntityFilters, NextPageLink, classNames, useEntity, DottedLine, useView } from '@shapeable/web';
import { InnovationHorizonTableList } from './innovation-horizon-table-list';
import { TabSpec, Tabs } from '../elements/tabs';
const cls = classNames('topic-main-layout');
import { compact } from 'lodash';
import { ExplorerView } from '../elements/explorer-view';
import { useInnovationsFilters } from '../../hooks/use-innovations-filters';
import { linkedMatchesFilter } from '../../utils/linked-matches-filter';

// -------- Types -------->

export type TopicView = 'deep-dive' | 'innovations' | 'library';
export type TopicMainLayoutProps = Classable & HasChildren & { 
  entity?: Topic;
};

export const TopicMainLayoutDefaultProps: Omit<TopicMainLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    font-size: 1em;
    display: flex;
    flex-direction: column;
  `,
});

const SectionStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.UNIT(5)};
    gap: ${theme.UNIT(5)};
  `,
});

const ContentTitleStyles = breakpoints({
  base: css`
    
  `,
});

const DescriptionStyles = breakpoints({
  base: css`
    
  `,
});

const InnovationTableStyles = breakpoints({
  base: css`
    
  `,
});

const TabsStyles = breakpoints({
  base: css`
    width: 100%;
  `,
});

const IntroStyles = breakpoints({
  base: css`
    font-size: 1.35em; 
  `,
});

const NextLinkStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(5)};
    
  `,
});

const FiltersStyles = breakpoints({
  base: css`
    
  `,
});


const BodyStyles = breakpoints({
  base: css`
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 330px);
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(5)};
  `,
  tablet: css`
    height: calc(100vh - 312px);
  `,
  desktop: css`
    height: calc(100vh - 366px);
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Section: styled.section`${SectionStyles}`,
      ContentTitle: styled.h2`${ContentTitleStyles}`,
      
      Intro: styled(ContentEntityIntro)`${IntroStyles}`,
      Description: styled(ContentNode)`${DescriptionStyles}`,
      Body: styled.div`${BodyStyles}`,
      InnovationTable: styled(InnovationHorizonTableList)`${InnovationTableStyles}`,
      

      Filters: styled(EntityFilters)`${FiltersStyles}`,
      Tabs: styled(Tabs)`${TabsStyles}`,

      NextLink: styled(NextPageLink)`${NextLinkStyles}`,

};

export const TopicMainLayout: Shapeable.FC<TopicMainLayoutProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);
  
  const { description } = entity;
  const hasLibrary = false; // todo - change this to be based on linked content
  const view = `topic-${entity.slug}`;
  const { filters } = useView(view);
  const { technologies = [], catalysts = [], horizonTypes = [] } = filters;
  const { selects } = useInnovationsFilters('topic');

  const tabs: TabSpec<TopicView>[] = compact([
    {
      id: 'deep-dive',
      label: 'Deep Dive',
    },
    !!entity.innovations.length && {
      id: 'innovations',
      label: 'Innovations',
    },
    hasLibrary && {
      id: 'library',
      label: 'Library',
    },
  ]);

  const { activeView, setActiveView } = useView<TopicView>(view, 'deep-dive');
  const innovations = entity.innovations.filter(innovation => (
    linkedMatchesFilter(innovation.technologies, technologies) &&
    linkedMatchesFilter(innovation.catalysts, catalysts) &&
    linkedMatchesFilter(compact(innovation.horizons?.map(h => h.type)), horizonTypes)
  ))
  
  return (
    <My.Container className={cls.name(className)}>
    {
      tabs.length > 1 &&
      <My.Tabs onTabClick={setActiveView} activeTab={activeView} tabs={tabs} variant='section' />
    }
    <ExplorerView>
    {
      activeView === 'deep-dive' &&
      <My.Section>
      <My.Intro entity={entity} />
      <My.Description entity={description as TContentNode} /> 
      <DottedLine height={2} />
      <My.NextLink />
      </My.Section>
    }
    {
      activeView === 'innovations' &&
      <My.Section>
        <My.Filters
          results={entity.innovations} 
          view={view}
          selects={selects} 
        />
        <My.Body>
          <My.InnovationTable items={innovations} />
          <DottedLine height={2} />
          <My.NextLink />
        </My.Body>
        
      </My.Section>
    }
    </ExplorerView>

    </My.Container>
  )
};

TopicMainLayout.defaultProps = TopicMainLayoutDefaultProps;
TopicMainLayout.cls = cls;